import Api from '@api';

import {YN, PAGER, CONSIGNMENT_STATUS} from '@constant';
import {setSelectClientList, setSelectOwnerList, setSelectPalletList, setSelectSiteList} from "../../../../api/common";
import {MOVE_TASK_QC_STATUS, MOVE_TASK_STATUS, NEW_MOVE_TASK_TYPE, SHIPMENT_LOCK_STATUS} from "../../../../constant";

const defaultPaginationInfo = {
  PAGE_NUM: PAGER.MIN_PAGE_NUM,
  PAGE_SIZE: PAGER.MAX_PAGE_SIZE
}

/**
 * @description 列表和筛选表单不会展示的组
 */
export const noFilterGroup = ['DemandOrderPrefix', 'OrderPrefix', 'PreadvicePrefix', 'UPIPrefix'];

export const getFormConfig = (vm) => {
  return {
    filterFormConfig: [{
      "key": "MOVE_TASK$TASK_ID",
      "label": "移动任务ID",
      "type": "magnifier",
      "tableName": 'MOVE_TASK',
      "placeholder": "请输入移动任务ID",
      "tabGroup": "mqs_move_task_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "MOVE_TASK$OWNER_ID",
      "label": "货主",
      "type": "magnifier",
      "placeholder": "请选择货主",
      "tabGroup": "mqs_move_task_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      // "options": async () => {
      //   let {status, data: {list}, message} = await setSelectOwnerList({
      //     WORKSTATION: {},
      //     ...defaultPaginationInfo
      //   });
      //
      //   if (Number(status) === 200) {
      //     return list.map(item=>{
      //       return{
      //         key:item.OWNER_ID,
      //         label:item.OWNER_ID
      //       }
      //     })
      //   }
      // },
    }, {
      "key": "MOVE_TASK$SITE_ID",
      "label": "站点",
      "type": "multiselect",
      "placeholder": "请选择站点",
      "tabGroup": "mqs_move_task_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "options": async () => {
        let {status, data: {list}, message} = await setSelectSiteList({
          SITE: {},
          ...defaultPaginationInfo
        });

        if (Number(status) === 200) {
          return list.map(item => {
            return {
              key: item.SITE_ID,
              label: item.SITE_ID
            }
          })
        }
      },
    }, {
      "key": "MOVE_TASK$LINE_ID",
      "label": "行号",
      "type": "numrange",
      "placeholder": "请选择行号",
      "tabGroup": "mqs_move_task_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "MOVE_TASK$TASK_TYPE",
      "label": "移动任务类型",
      "type": "multiselect",
      "placeholder": "请选择移动任务类型",
      "tabGroup": "mqs_move_task_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "options": NEW_MOVE_TASK_TYPE,
      "formatter": ({cellValue, row, column}) => {
        let res = NEW_MOVE_TASK_TYPE.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "MOVE_TASK$CLIENT_ID",
      "label": "客户端 ID",
      "type": "multiselect",
      "placeholder": "请选择客户端 ID",
      "tabGroup": "mqs_move_task_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "options": async () => {
        let {status, data: {list}, message} = await setSelectClientList({
          WORKSTATION: {},
          ...defaultPaginationInfo
        });

        if (Number(status) === 200) {
          return list.map(item => {
            return {
              key: item.CLIENT_ID,
              label: item.CLIENT_ID
            }
          })
        }
      },
    }, {
      "key": "MOVE_TASK$TAG_ID",
      "label": "标记ID",
      "type": "magnifier",
      "tableName": 'MOVE_TASK',
      "placeholder": "请输入标记ID",
      "tabGroup": "mqs_move_task_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "MOVE_TASK$QTY_TO_MOVE",
      "label": "移动数量",
      "type": "numrange",
      "placeholder": "请选择移动数量",
      "tabGroup": "mqs_move_task_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "MOVE_TASK$WORK_ZONE",
      "label": "工作区域",
      "type": "magnifier",
      "tableName": 'MOVE_TASK',
      "placeholder": "请输入工作区域",
      "tabGroup": "mqs_move_task_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "MOVE_TASK$SKU_ID",
      "label": "SKU",
      "type": "magnifier",
      "placeholder": "请输入SKU",
      "tabGroup": "mqs_move_task_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "_DESCRIPTION_",
      "label": "描述",
      "type": "textarea",
      "placeholder": "",
      "tabGroup": "mqs_move_task_tab0",
      "tabGroupName": "综合",
      "showBlock": [
        "dataInfo"
      ],
      "col": "2",
    }, {
      "key": "MOVE_TASK$PRIORITY",
      "label": "优先级",
      "type": "numrange",
      "placeholder": "请选择优先级",
      "tabGroup": "mqs_move_task_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "MOVE_TASK$USER_ID",
      "label": "用户 ID",
      "type": "magnifier",
      "tableName": 'MOVE_TASK',
      "placeholder": "请输入用户 ID",
      "tabGroup": "mqs_move_task_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "MOVE_TASK$DESCRIPTION",
      "label": "描述",
      "type": "autoComplete",
      "tableName": "MOVE_TASK",
      "placeholder": "请输入描述",
      "tabGroup": "mqs_move_task_tab0",
      "tabGroupName": "综合",
      "showBlock": [],
      "checked": false,
      "col": 2
    }, {
      "key": "MOVE_TASK$STATION_ID",
      "label": "工作站",
      "type": "magnifier",
      "tableName": 'MOVE_TASK',
      "placeholder": "请输入工作站",
      "tabGroup": "mqs_move_task_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "MOVE_TASK$FROM_LOC_ID",
      "label": "从库位",
      "type": "magnifier",
      "tableName": 'MOVE_TASK',
      "placeholder": "请输入从库位",
      "tabGroup": "mqs_move_task_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "MOVE_TASK$DSTAMP",
      "label": "移动日期",
      "type": "date",
      "placeholder": "请选择移动日期",
      "tabGroup": "mqs_move_task_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "MOVE_TASK$TIME",
      "label": "移动时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择移动时间",
      "tabGroup": "mqs_move_task_tab0",
      "tabGroupName": "综合",
      "showBlock": ["dataInfo"],
      "checked": false
    }, {
      "key": "MOVE_TASK$TO_LOC_ID",
      "label": "至库位",
      "type": "magnifier",
      "tableName": 'MOVE_TASK',
      "placeholder": "请输入从库位",
      "tabGroup": "mqs_move_task_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "MOVE_TASK$CUSTOMER_ID",
      "label": "客户",
      "type": "magnifier",
      "tableName": 'MOVE_TASK',
      "placeholder": "请输入客户",
      "tabGroup": "mqs_move_task_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "MOVE_TASK$STATUS",
      "label": "状态",
      "type": "multiselect",
      "placeholder": "请选择状态",
      "tabGroup": "mqs_move_task_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "options": MOVE_TASK_STATUS,
      "formatter": ({cellValue, row, column}) => {
        let res = MOVE_TASK_STATUS.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "MOVE_TASK$CONFIG_ID",
      "label": "打包配置",
      "type": "magnifier",
      "tableName": 'MOVE_TASK',
      "placeholder": "请输入打包配置",
      "tabGroup": "mqs_move_task_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "MOVE_TASK$FINAL_LOC_ID",
      "label": "最终位置",
      "type": "magnifier",
      "tableName": 'MOVE_TASK',
      "placeholder": "请输入最终位置",
      "tabGroup": "mqs_move_task_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "MOVE_TASK$LIST_ID",
      "label": "列表ID",
      "type": "magnifier",
      "tableName": 'MOVE_TASK',
      "placeholder": "请输入列表ID",
      "tabGroup": "mqs_move_task_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "MOVE_TASK$WORK_GROUP",
      "label": "工作组",
      "type": "magnifier",
      "tableName": 'MOVE_TASK',
      "placeholder": "请输入工作组",
      "tabGroup": "mqs_move_task_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "MOVE_TASK$CONSIGNMENT",
      "label": "托运",
      "type": "magnifier",
      "tableName": 'MOVE_TASK',
      "placeholder": "请输入托运",
      "tabGroup": "mqs_move_task_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "MOVE_TASK$SHIPMENT_GROUP",
      "label": "发运组",
      "type": "magnifier",
      "tableName": 'MOVE_TASK',
      "placeholder": "请输入发运组",
      "tabGroup": "mqs_move_task_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "MOVE_TASK$PALLET_CONFIG",
      "label": "托盘类型",
      "type": "multiselect",
      "placeholder": "请选择托盘类型",
      "tabGroup": "mqs_move_task_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "options": async () => {
        let {status, data: {list}, message} = await setSelectPalletList({
          PALLET_CONFIG: {},
          ...defaultPaginationInfo
        });

        if (Number(status) === 200) {
          return list.map(item => {
            return {
              key: item.CONFIG_ID,
              label: item.CONFIG_ID
            }
          })
        }
      },
    },
      // {
      //   "key": "MOVE_TASK$PALLET_ID",
      //   "label": "托盘号",
      //   "type": "autoComplete",
      //   "tableName": "MOVE_TASK",
      //   "placeholder": "请选择托盘号",
      //   "tabGroup": "mqs_move_task_tab1",
      //   "tabGroupName": "杂项",
      //   "showBlock": ["all"],
      //   "checked": false
      // },
      {
        "key": "MOVE_TASK$CONTAINER_ID",
        "label": "容器号",
        "type": "numrange",
        "placeholder": "请选择容器号",
        "tabGroup": "mqs_move_task_tab1",
        "tabGroupName": "杂项",
        "showBlock": ["all"],
        "checked": false
      },
      /**
       * @desc SESSION_TYPE 为进程类型
       */
      {
        "key": "MOVE_TASK$SEQUENCE",
        "label": "序列",
        "type": "numrange",
        "placeholder": "请选择序列",
        "tabGroup": "mqs_move_task_tab1",
        "tabGroupName": "杂项",
        "showBlock": ["all"],
        "checked": false
      }, {
        "key": "MOVE_TASK$PRINT_LABEL_ID",
        "label": "拣选标签ID",
        "type": "magnifier",
        "tableName": 'MOVE_TASK',
        "placeholder": "请输入拣选标签ID",
        "tabGroup": "mqs_move_task_tab1",
        "tabGroupName": "杂项",
        "showBlock": ["all"],
        "checked": false
      }, {
        "key": "MOVE_TASK$ORIGIN_ID",
        "label": "产地",
        "type": "magnifier",
        "placeholder": "请输入产地",
        "tabGroup": "mqs_move_task_tab1",
        "tabGroupName": "杂项",
        "showBlock": ["all"],
        "checked": false
      }, {
        "key": "MOVE_TASK$CONDITION_ID",
        "label": "条件代码",
        "type": "magnifier",
        "placeholder": "请输入条件代码",
        "tabGroup": "mqs_move_task_tab1",
        "tabGroupName": "杂项",
        "showBlock": ["all"],
        "checked": false
      }, {
        "key": "MOVE_TASK$CATCH_WEIGHT",
        "label": "捕获重量",
        "type": "numrange",
        "placeholder": "请选择捕获重量",
        "tabGroup": "mqs_move_task_tab1",
        "tabGroupName": "杂项",
        "showBlock": ["all"],
        "checked": false
      }, {
        "key": "MOVE_TASK$STAGE_ROUTE_ID",
        "label": "分段路线ID",
        "type": "magnifier",
        "tableName": 'MOVE_TASK',
        "placeholder": "请输入分段路线ID",
        "tabGroup": "mqs_move_task_tab1",
        "tabGroupName": "杂项",
        "showBlock": ["all"],
        "checked": false
      }, {
        "key": "MOVE_TASK$STAGE_ROUTE_SEQUENCE",
        "label": "分段路线顺序",
        "type": "magnifier",
        "tableName": 'MOVE_TASK',
        "placeholder": "请输入分段路线顺序",
        "tabGroup": "mqs_move_task_tab1",
        "tabGroupName": "杂项",
        "showBlock": ["all"],
        "checked": false
      }, {
        "key": "MOVE_TASK$SERIAL_NUMBER",
        "label": "分配的序列号",
        "type": "magnifier",
        "tableName": 'MOVE_TASK',
        "placeholder": "请输入分配的序列号",
        "tabGroup": "mqs_move_task_tab1",
        "tabGroupName": "杂项",
        "showBlock": ["all"],
        "checked": false
      }, {
        "key": "MOVE_TASK$LABELLING",
        "label": "贴标签",
        "type": "select",
        "placeholder": "请选择贴标签",
        "tabGroup": "mqs_move_task_tab1",
        "tabGroupName": "杂项",
        "showBlock": ["all"],
        "checked": false,
        "options": YN,
        "formatter": ({cellValue, row, column}) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      }, {
        "key": "MOVE_TASK$KIT_PLAN_ID",
        "label": "套装计划ID",
        "type": "magnifier",
        "tableName": 'MOVE_TASK',
        "placeholder": "请输入套装计划ID",
        "tabGroup": "mqs_move_task_tab1",
        "tabGroupName": "杂项",
        "showBlock": ["all"],
        "checked": false
      }, {
        "key": "MOVE_TASK$PLAN_SEQUENCE",
        "label": "计划顺序",
        "type": "numrange",
        "placeholder": "请选择计划顺序",
        "tabGroup": "mqs_move_task_tab1",
        "tabGroupName": "杂项",
        "showBlock": ["all"],
        "checked": false
      }, {
        "key": "MOVE_TASK$CONSOL_RUN_NUM",
        "label": "合并分配号码",
        "type": "autoComplete",
        "tableName": "MOVE_TASK",
        "placeholder": "请输入合并分配号码",
        "tabGroup": "mqs_move_task_tab1",
        "tabGroupName": "杂项",
        "showBlock": ["all"],
        "checked": false
      }, {
        "key": "MOVE_TASK$CONSOL_LINK",
        "label": "关联",
        "type": "autoComplete",
        "tableName": "MOVE_TASK",
        "placeholder": "请输入关联",
        "tabGroup": "mqs_move_task_tab1",
        "tabGroupName": "杂项",
        "showBlock": ["all"],
        "checked": false
      }, {
        "key": "INVENTORY$BATCH_ID",
        "label": "批次",
        "type": "magnifier",
        "tableName": 'INVENTORY',
        "placeholder": "请输入批次",
        "tabGroup": "mqs_inventory_tab2",
        "tabGroupName": "库存属性",
        "showBlock": ["all"],
        "checked": false
      }, {
        "key": "INVENTORY$LOCK_STATUS",
        "label": "锁定状态",
        "type": "multiselect",
        "placeholder": "请选择锁定状态",
        "tabGroup": "mqs_inventory_tab2",
        "tabGroupName": "库存属性",
        "showBlock": ["all"],
        "checked": false,
        "options": SHIPMENT_LOCK_STATUS,
        "formatter": ({cellValue, row, column}) => {
          let res = SHIPMENT_LOCK_STATUS.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      }, {
        "key": "INVENTORY$LOCK_CODE",
        "label": "锁定 代码 ",
        "type": "magnifier",
        "placeholder": "请输入锁定 代码 ",
        "tabGroup": "mqs_inventory_tab2",
        "tabGroupName": "库存属性",
        "showBlock": ["all"],
        "checked": false
      }, {
        "key": "INVENTORY$QC_STATUS",
        "label": "QC状态",
        "type": "multiselect",
        "placeholder": "请选择QC状态",
        "tabGroup": "mqs_inventory_tab2",
        "tabGroupName": "库存属性",
        "showBlock": ["all"],
        "checked": false,
        "options": MOVE_TASK_QC_STATUS,
        "formatter": ({cellValue, row, column}) => {
          let res = MOVE_TASK_QC_STATUS.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      }, {
        "key": "INVENTORY$MANUF_DSTAMP",
        "label": "生产日期",
        "type": "date",
        "placeholder": "请选择生产日期",
        "tabGroup": "mqs_inventory_tab2",
        "tabGroupName": "库存属性",
        "showBlock": ["all"],
        "checked": false
      }, {
        "key": "INVENTORY$MANUF_TIME",
        "label": "生产时间",
        "type": "time",
        "props": {
          "value-format": "HH:mm:ss"
        },
        "placeholder": "请选择生产时间",
        "tabGroup": "mqs_inventory_tab2",
        "tabGroupName": "库存属性",
        "showBlock": ["dataInfo"],
        "checked": false
      }, {
        "key": "INVENTORY$EXPIRY_DSTAMP",
        "label": "失效日期",
        "type": "date",
        "placeholder": "请选择失效日期",
        "tabGroup": "mqs_inventory_tab2",
        "tabGroupName": "库存属性",
        "showBlock": ["all"],
        "checked": false
      }, {
        "key": "INVENTORY$EXPIRY_TIME",
        "label": "失效时间",
        "type": "time",
        "props": {
          "value-format": "HH:mm:ss"
        },
        "placeholder": "请选择失效时间",
        "tabGroup": "mqs_inventory_tab2",
        "tabGroupName": "库存属性",
        "showBlock": ["dataInfo"],
        "checked": false
      }, {
        "key": "INVENTORY$USER_DEF_TYPE_1",
        "label": "客户ID",
        "type": "autoComplete",
        "tableName": "INVENTORY",
        "placeholder": "请输入客户ID",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "库存用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false
      }, {
        "key": "INVENTORY$USER_DEF_TYPE_2",
        "label": "产地",
        "type": "autoComplete",
        "tableName": "INVENTORY",
        "placeholder": "请输入产地",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "库存用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false
      }, {
        "key": "INVENTORY$USER_DEF_TYPE_3",
        "label": "客户订单号",
        "type": "autoComplete",
        "tableName": "INVENTORY",
        "placeholder": "客户订单号",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "库存用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false
      }, {
        "key": "INVENTORY$USER_DEF_TYPE_4",
        "label": "ETQ客户单号",
        "type": "autoComplete",
        "tableName": "INVENTORY",
        "placeholder": "ETQ客户单号",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "库存用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false
      }, {
        "key": "INVENTORY$USER_DEF_TYPE_5",
        "label": "工厂号",
        "type": "autoComplete",
        "tableName": "INVENTORY",
        "placeholder": "请输入工厂号",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "库存用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false
      }, {
        "key": "INVENTORY$USER_DEF_TYPE_6",
        "label": "集装箱号",
        "type": "autoComplete",
        "tableName": "INVENTORY",
        "placeholder": "请输入集装箱号",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "库存用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false
      }, {
        "key": "INVENTORY$USER_DEF_TYPE_7",
        "label": "虚拟库位号",
        "type": "autoComplete",
        "tableName": "INVENTORY",
        "placeholder": "请输入虚拟库位号",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "库存用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false
      }, {
        "key": "INVENTORY$USER_DEF_TYPE_8",
        "label": "报检号",
        "type": "autoComplete",
        "tableName": "INVENTORY",
        "placeholder": "报检号",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "库存用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false
      }, {
        "key": "INVENTORY$USER_DEF_CHK_1",
        "label": "用户自定义的检查1 ",
        "type": "select",
        "tableName": "INVENTORY",
        "placeholder": "请输入用户自定义的检查1 ",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "库存用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "options": YN,
        "formatter": ({cellValue, row, column}) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      }, {
        "key": "INVENTORY$USER_DEF_CHK_2",
        "label": "用户自定义的检查2 ",
        "type": "select",
        "tableName": "INVENTORY",
        "placeholder": "请输入用户自定义的检查2",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "库存用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "options": YN,
        "formatter": ({cellValue, row, column}) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      }, {
        "key": "INVENTORY$USER_DEF_CHK_3",
        "label": "用户自定义的检查3",
        "type": "select",
        "tableName": "INVENTORY",
        "placeholder": "请输入用户自定义的检查3",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "库存用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "options": YN,
        "formatter": ({cellValue, row, column}) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      }, {
        "key": "INVENTORY$USER_DEF_CHK_4",
        "label": "用户自定义的检查4",
        "type": "select",
        "tableName": "INVENTORY",
        "placeholder": "请输入用户自定义的检查4",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "库存用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "options": YN,
        "formatter": ({cellValue, row, column}) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      }, {
        "key": "INVENTORY$USER_DEF_DATE_1",
        "label": "订单生产日期",
        "type": "date",
        "placeholder": "请选择用户自定义日期 1 ",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "库存用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false
      }, {
        "key": "INVENTORY$USER_DEF_TIME_1",
        "label": "用户自定义时间1",
        "type": "time",
        "props": {
          "value-format": "HH:mm:ss"
        },
        "placeholder": "请选择 用户自定义时间1",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "库存用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false
      }, {
        "key": "INVENTORY$USER_DEF_DATE_2",
        "label": "用户自定义日期2",
        "type": "date",
        "placeholder": "请选择用户自定义日期2",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "库存用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false
      }, {
        "key": "INVENTORY$USER_DEF_TIME_2",
        "label": "用户自定义时间2",
        "type": "time",
        "props": {
          "value-format": "HH:mm:ss"
        },
        "placeholder": "请选择 用户自定义时间2",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "库存用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false
      }, {
        "key": "INVENTORY$USER_DEF_DATE_3",
        "label": "用户自定义日期3",
        "type": "date",
        "placeholder": "请选择用户自定义日期3",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "库存用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false
      }, {
        "key": "INVENTORY$USER_DEF_TIME_3",
        "label": "用户自定义时间3",
        "type": "time",
        "props": {
          "value-format": "HH:mm:ss"
        },
        "placeholder": "请选择 用户自定义时间3",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "库存用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false
      }, {
        "key": "INVENTORY$USER_DEF_DATE_4",
        "label": "用户自定义日期4",
        "type": "date",
        "placeholder": "请选择用户自定义日期4",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "库存用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false
      }, {
        "key": "INVENTORY$USER_DEF_TIME_4",
        "label": "用户自定义时间4",
        "type": "time",
        "props": {
          "value-format": "HH:mm:ss"
        },
        "placeholder": "请选择 用户自定义时间4",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "库存用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false
      }, {
        "key": "INVENTORY$USER_DEF_NUM_1",
        "label": "用户自定义号码1 ",
        "type": "autoComplete",
        "tableName": "INVENTORY",
        "placeholder": "请输入用户自定义号码1",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "库存用户自定义的_设置1",
        "showBlock": ["dataInfo"],
        "checked": false
      }, {
        "key": "INVENTORY$USER_DEF_NUM_2",
        "label": "用户自定义号码2",
        "type": "autoComplete",
        "tableName": "INVENTORY",
        "placeholder": "请输入用户自定义号码2",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "库存用户自定义的_设置1",
        "showBlock": ["dataInfo"],
        "checked": false
      }, {
        "key": "INVENTORY$USER_DEF_NUM_3",
        "label": "整改标记",
        "type": "autoComplete",
        "tableName": "INVENTORY",
        "placeholder": "请输入整改标记",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "库存用户自定义的_设置1",
        "showBlock": ["dataInfo"],
        "checked": false
      }, {
        "key": "INVENTORY$USER_DEF_NUM_4",
        "label": "用户自定义号码4",
        "type": "autoComplete",
        "tableName": "INVENTORY",
        "placeholder": "请输入用户自定义号码4",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "库存用户自定义的_设置1",
        "showBlock": ["dataInfo"],
        "checked": false
      }, {
        "key": "INVENTORY$USER_DEF_NOTE_1",
        "label": "备注",
        "type": "autoComplete",
        "tableName": "INVENTORY",
        "placeholder": "请输入备注",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "库存用户自定义的_设置1",
        "showBlock": ["dataInfo"],
        "checked": false
      }, {
        "key": "INVENTORY$USER_DEF_NOTE_2",
        "label": "产品组 ",
        "type": "autoComplete",
        "tableName": "INVENTORY",
        "placeholder": "请输入产品组",
        "tabGroup": "mqs_inventory_tab30",
        "tabGroupName": "库存用户自定义的_设置1",
        "showBlock": ["dataInfo"],
        "checked": false
      }, {
        "key": "MOVE_TASK$BOL_ID",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$CE_AVAIL_STATUS",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$CE_ROTATION_ID",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$CE_UNDER_BOND",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$CONSOLIDATED_TASK",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$CONTAINER_CONFIG",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$DECONSOLIDATE",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$DISALLOW_TAG_SWAP",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$DUE_LINE_ID",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$DUE_TASK_ID",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$DUE_TYPE",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$ESTIMATED_TIME",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$FACE_KEY",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$FACE_TYPE",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$FINISH_DSTAMP",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$FIRST_KEY",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$FIRST_PICK",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$INCREMENT_TIME",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$INV_KEY",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$KEY",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$KIT_LINE_ID",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$KIT_LINK",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$KIT_RATIO",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$KIT_SKU_ID",
        "label": "",
        "type": "magnifier",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$LABEL_EXCEPTIONED",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$LABOR_ASSIGNMENT",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$LABOR_GRID_SEQUENCE",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$LIST_TO_CONTAINER_ID",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$LIST_TO_PALLET_ID",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$MOVE_WHOLE",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$MOVED_LOCK_STATUS",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$OLD_FROM_LOC_ID",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$OLD_QTY_TO_MOVE",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$OLD_STATUS",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$OLD_TAG_ID",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$OLD_TASK_ID",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$OLD_TO_LOC_ID",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$ORIGINAL_DSTAMP",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$PALLET_DEPTH",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$PALLET_GROUPED",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$PALLET_HEIGHT",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$PALLET_ID",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$PALLET_VOLUME",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$PALLET_WEIGHT",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$PALLET_WIDTH",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$PF_CONSOL_LINK",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$PICK_REALLOC_FLAG",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$PRINT_LABEL",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$PROCESSING",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$RDT_USER_MODE",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$REASON_CODE",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$REPACK",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$REPACK_QC_DONE",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$SEQUENCE",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$START_DSTAMP",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$SUMMARY_RECORD",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$TO_CONTAINER_CONFIG",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$TO_CONTAINER_ID",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$TO_LOC_ID",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$TO_PALLET_CONFIG",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$TO_PALLET_DEPTH",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$TO_PALLET_HEIGHT",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$TO_PALLET_ID",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$TO_PALLET_VOLUME",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$TO_PALLET_WEIGHT",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$TO_PALLET_WIDTH",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$TRAILER_POSITION",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$TROLLEY_SLOT_ID",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$UPLOADED_LABOR",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$USER_DEF_MATCH_BLANK_1",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$USER_DEF_MATCH_BLANK_2",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$USER_DEF_MATCH_BLANK_3",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$USER_DEF_MATCH_BLANK_4",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$USER_DEF_MATCH_BLANK_5",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$USER_DEF_MATCH_BLANK_6",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$USER_DEF_MATCH_BLANK_7",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$USER_DEF_MATCH_BLANK_8",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$USER_DEF_MATCH_CHK_1",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$USER_DEF_MATCH_CHK_2",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$USER_DEF_MATCH_CHK_3",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "MOVE_TASK$USER_DEF_MATCH_CHK_4",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$AB_LAST_TRANS_DSTAMP",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$BEAM_END_UNIT",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$BEAM_ID",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$BEAM_START_UNIT",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$BREAKPACK_DONE",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$CE_AVAIL_STATUS",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$CE_CONSIGNMENT_ID",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$CE_COO",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$CE_CWC",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$CE_DOCUMENT_DSTAMP",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$CE_DUTY_STAMP",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$CE_INVOICE_NUMBER",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$CE_ORIGINATOR",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$CE_ORIGINATOR_REFERENCE",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$CE_RECEIPT_TYPE",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$CE_ROTATION_ID",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$CE_UCR",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$CE_UNDER_BOND",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$CLIENT_ID",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$CONDITION_ID",
        "label": "",
        "type": "magnifier",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$CONFIG_ID",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$CONTAINER_ID",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$COUNT_DSTAMP",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$COUNT_NEEDED",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$CREATED_INVENTORY",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$DESCRIPTION",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$DISALLOW_ALLOC",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$ENTERED_HEIGHT",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$EXPIRED",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$FROM_SITE_ID",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$FULL_PALLET",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$KEY",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$LINE_ID",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$LOCATION_ID",
        "label": "",
        "type": "magnifier",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$MOVE_DSTAMP",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$NEXT_SAMPLING_ACTION",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$NOTES",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$ORIGIN_ID",
        "label": "",
        "type": "magnifier",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$OUTER",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$OWNER_ID",
        "label": "",
        "type": "magnifier",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$PALLET_CONFIG",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$PALLET_DEPTH",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$PALLET_HEIGHT",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$PALLET_ID",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$PALLET_VOLUME",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$PALLET_WEIGHT",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$PALLET_WIDTH",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$PICK_FACE",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$PRE_RECEIVED",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$QTY_ALLOCATED",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$QTY_ON_HAND",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$RECEIPT_DSTAMP",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$RECEIPT_ID",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$RECEIPT_TYPE",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$SAMPLED",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$SAMPLING_TYPE",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$SITE_ID",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$SKU_ID",
        "label": "",
        "type": "magnifier",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$SPEC_CODE",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$SUPPLIER_ID",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$TAG_ID",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$TO_SITE_ID",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }, {
        "key": "INVENTORY$ZONE_1",
        "label": "",
        "type": "input",
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      },
      {
        "key": "MOVE_TASK$TASK_TYPE",
        "label": "移动任务类型",
        "type": "multiselect",
        "placeholder": "请选择移动任务类型",
        "tabGroup": "THead",
        "tabGroupName": "",
        "showBlock": ["dataInfo"],
        "checked": false,
        "options": NEW_MOVE_TASK_TYPE,
        "formatter": ({cellValue, row, column}) => {
          let res = NEW_MOVE_TASK_TYPE.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      },
      {
        "key": "MOVE_TASK$CLIENT_ID",
        "label": "客户端 ID",
        "type": "multiselect",
        "placeholder": "请选择客户端 ID",
        "tabGroup": "THead",
        "tabGroupName": "",
        "showBlock": ["dataInfo"],
        "checked": false,
        "options": async () => {
          let {status, data: {list}, message} = await setSelectClientList({
            WORKSTATION: {},
            ...defaultPaginationInfo
          });

          if (Number(status) === 200) {
            return list.map(item => {
              return {
                key: item.CLIENT_ID,
                label: item.CLIENT_ID
              }
            })
          }
        },
      },
      {
        "key": "MOVE_TASK$OWNER_ID",
        "label": "货主",
        "type": "magnifier",
        "placeholder": "请选择货主",
        "tabGroup": "THead",
        "tabGroupName": "",
        "showBlock": ["dataInfo"],
        "checked": false,
      },
      {
        "key": "MOVE_TASK$SKU_ID",
        "label": "SKU",
        "type": "magnifier",
        "placeholder": "请输入SKU",
        "tabGroup": "THead",
        "tabGroupName": "",
        "showBlock": ["dataInfo"],
        "checked": false
      },
      {
        "key": "MOVE_TASK$TAG_ID",
        "label": "标记ID",
        "type": "magnifier",
        "tableName": 'MOVE_TASK',
        "placeholder": "请输入标记ID",
        "tabGroup": "THead",
        "tabGroupName": "",
        "showBlock": ["dataInfo"],
        "checked": false
      },
      {
        "key": "MOVE_TASK$SITE_ID",
        "label": "站点",
        "type": "multiselect",
        "placeholder": "请选择站点",
        "tabGroup": "THead",
        "tabGroupName": "综合",
        "showBlock": ["dataInfo"],
        "checked": false,
        "options": async () => {
          let {status, data: {list}, message} = await setSelectSiteList({
            SITE: {},
            ...defaultPaginationInfo
          });

          if (Number(status) === 200) {
            return list.map(item => {
              return {
                key: item.SITE_ID,
                label: item.SITE_ID
              }
            })
          }
        },
      }
    ]
  }
}