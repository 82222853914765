import {getFormConfig} from "./formConfig";
import { isCanShowBlock } from '@utils/utils';

export const getTableColumn = vm => {
  const formConfigList = getFormConfig().filterFormConfig.filter(item => item.label && isCanShowBlock('tableList', item.showBlock));
  const list = formConfigList.map(item => {
    return {
      title: item.label,
      field: item.key,
      sortable: true,
      tag: item.key,
      formatter: item.formatter
    }
  })

  const fixedList = [
    { type: 'seq', title: '序号', width: '60', tag: 'seq' },
    { type: 'checkbox', title: '全选/反选', width: '120', tag: 'checkbox' },
  ];

  const opList = [
    {
      field: "edit",
      title: "操作",
      fixed: 'right',
      width: '150',
      tag: 'edit',
      _renderType: true,
      render: (h, {data}) => {
        let { row } = data;
        return (
          <div class={'edit'}>
            {/* <el-button type="text" onClick={() => { vm.clientEdit(row, 'clientStatus') }}>启用</el-button> */}
            <el-button type="text" onClick={() => { vm.clientEdit({'MOVE_TASK$KEY':row['MOVE_TASK$KEY']}, 'detail') }}>详情</el-button>
            {/* <el-button type="text" onClick={() => { vm.clientEdit(row, 'clientEdit') }}>修改</el-button> */}
          </div>
        );
      }
    },
  ]
  return {
    tableColumn:[...fixedList, ...list, ...opList]
  }
}